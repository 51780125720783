import localIdb from 'localIdb';
import { useEffect } from 'react';

export default function States() {
  const {open, getAll, getById} = localIdb();
  
  useEffect(() => {
    open();
  }, []);

  const getAllStates = async () =>  (await getAll("worksorderStates")).result;

  const getStateById = async id => (await getAll("worksorderStates")).result.filter(i => i.id === id)[0];

  const getStateByCode = async code => (await getAll("worksorderStates")).result.filter(i => i.code === code)[0];

  return {
    getAllStates,
    getStateById,
    getStateByCode,
    state: {
      inProgress: () => getStateByCode("IN-PROGRESS"),
      sending: () => getStateByCode("SENDING"),
      send: () => getStateByCode("SEND")
    }
  }
}
