import {selector, atom } from 'recoil';
import {pusherAtom, pusherSelector} from 'components/PusherRecoil';
import service from "api/service";
import {api} from 'data/useData';

const worksordersSelector = selector({
  key: 'worksordersSelector',
  get: async () => {
    const response = await api.worksorders();    
    return response.data.items;
  },
  dangerouslyAllowMutability: true  
})

export const worksordersPusherAtom = pusherAtom({
  section: 'worksorders',
  defaults: worksordersSelector
})

export const addWorksorderPusherSelector = pusherSelector({
  key: "add-item",
  section: 'worksorders',
  set: (state, data) => {
    return [...state, data];
  }
});

export const updateWorksorderPusherSelector = pusherSelector({
  key: 'update-item',
  section: 'worksorders',
  set: (state, {id, data}) => {
    return state.map(item => {
      if (item.id === id) 
        return {...item, ...data};
      else 
        return item;
    });
  }
})

const worksorderSelector = selector({
  key: 'worksorderSelector',
  get: async ({get}) => {
    const worksorderId = get(worksorderIdAtom);
    if (!worksorderId)
      return {};
      
      console.log("🚀 ~ file: worksorders.js:43 ~ get: ~ worksorderId", worksorderId)
      const response = await api.worksorder(worksorderId);
    return response.data;
  },
  dangerouslyAllowMutability: true  
});

export const worksorderIdAtom = atom({
  key: 'worksorderIdAtom',
  default: 0
});

export const worksorderAtom = atom({
  key: 'worksorderAtom',
  default: worksorderSelector,
  dangerouslyAllowMutability: true
});
