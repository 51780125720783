import React, { useEffect, useReducer } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  PageContext,
  usePageContext,
  PageReducer,
} from "@bit/hlouzek.rhplus.page/page";
import configureStore, { history } from "./store";
import App from "./containers/App";
import localIdb from "localIdb";
import CacheBuster from "@bit/hlouzek.rhplus.cache-buster";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import { DraggableModalProvider } from "ant-design-draggable-modal";
import "ant-design-draggable-modal/dist/index.css";
import "styles/app.scss";
import { ConfigProvider } from "antd";

// configuraton AgGrid Enterprise
LicenseManager.setLicenseKey(
  "MTU1MzczMTIwMDAwMA==555b6946f1f4ee082a509e13fbc32c10"
);

// configuration Redux store
export const store = configureStore();

const Apps = (props) => {
  const initialPageContext = usePageContext();
  const [pageState, pageDispatch] = useReducer(PageReducer, initialPageContext);
  const { open } = localIdb();

  useEffect(() => {
    var store = open();
  }, []);

  return (
    <CacheBuster packageJson={import("../package.json")}>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          <DraggableModalProvider>
            <PageContext.Provider value={{ pageState, pageDispatch }}>
              <App {...props} />
            </PageContext.Provider>
          </DraggableModalProvider>
        );
      }}
    </CacheBuster>
  );
};

export default function MainApp() {
  return (
    <Provider store={store}>
      <BrowserRouter history={history}>
        <ConfigProvider>
          <Switch>
            <Route path="/" component={Apps} />
          </Switch>
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  );
}
