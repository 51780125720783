import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import {useApiContext} from './Context/ApiContext';
// import {adalGetToken} from 'react-adal/lib/react-adal';
import {errorNotification} from './Ant';

// import {
//     IErrorArgsProps,
//     ICallApi,
//     ICredentials
// } from './Interfaces';

export const ApplicationJsonHeader = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Headers": "Origin, Content-Type, Authorization, Accept, X-Request-With",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    
};

const DefaultVersion = "1.0";

export const CompressionHeader = {
    "Accept-Encoding": "br",
    "Content-Encoding": "Vary"
}

export const getErrorFromResponse = (response) => {
    const {response: { data = {}} = {}} = response;
    if (isEmpty(data)) {
            return {
              message: "API error, see console for details.",
              response
            };
    } else {
        const {error, stackTrace} = data;
        if (!error || !error.length) {
            return {
              message: "API error, see console for details.",
              response
            }
        } else {
            var message = error.join(". ");
            return {
              message,
              response: {}
            }
        }
    }
}

export const useApiComponent = (endpoint) => {
    return {
        callApi: CallApiBase(endpoint)
    }
}

export const UseApi = (endpoint, authContext) => {
    let context = useApiContext();
    if (!!authContext) {
        context = authContext;
    }
    return {
        callApi: CallApiBase(endpoint, context)
    }
}

export const callApi = async ({
    methodType, 
    url, 
    paramObject = {}, 
    headers = ApplicationJsonHeader, 
    responseType, 
    compression = true,
    version = DefaultVersion,
    withCredentials = false,
    authContext = {},
    showErrorNotification = true,
    ownErrorNotification
}) => {
    
    let credentials = {};
    // if (withCredentials) {
    //     const token = await adalGetToken(authContext, authContext.config.clientId)
    //     credentials = {
    //         withCredentials,
    //         token
    //     }
    // }
    const config = {
        url: url,
        method: methodType,
        headers: getHeaders(headers, compression, version, credentials),
        responseType: responseType,
        data: paramObject,
        timeout: 36000000,
        maxContentLength: 50000000,
    };
    // return axios(config);
    
    var processPromise = new Promise((resolve, reject) => {
        console.log("axios", config);
        axios(config)
            .then(response => {
                resolve(response)
            } )
            .catch(error => {
                const log = {
                    message: error,
                    config
                }
                console.log("api error", log);
                if (showErrorNotification) {
                    if (ownErrorNotification) {
                        ownErrorNotification(error);
                    } else {
                        errorNotification(error);
                    }
                }
                reject(error);
            } )
    }); 
    return processPromise;
}

const CallApiBase = (endpoint, context) => (callApiParameters) => {
    return callApi({...context, ...callApiParameters, url: `${endpoint || ''}${callApiParameters.url}`});
}

const getHeaders = (headers, compression, version, credentials) => {
    var _version = {};
    var _compression = {};
    var _credentials = {};

    if (!isEmpty(version)) {
        _version = { "api-version": version }
    }

    if (compression) {
        _compression = CompressionHeader;
    }
    if (credentials && credentials.withCredentials) {
        _credentials = { 
            Authorization: `Bearer ${credentials.token}`
        }            
    }

    return {
        ...headers,
        ..._compression, 
        ..._version,
        ..._credentials
    }
}

