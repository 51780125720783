import * as React from "react";
import {
  worksordersPusherAtom,
  worksorderIdAtom,
  worksorderAtom,
  addWorksorderPusherSelector,
  updateWorksorderPusherSelector,
} from "recoils/worksorders";
import { useRecoilState, useRecoilValue } from "recoil";
import { UsePusherRecoilValue } from "@bit/rhplus.wieldy.pusher-recoil/index";
import service from "api/service";
import { useApiComponent } from "@bit/hlouzek.rhplus.api/Api";
import moment from "moment";
import useStates from "lookups/useStates";
import { states } from "constants/states";
import { UsePusherRecoilSetter } from "@bit/rhplus.wieldy.pusher-recoil/index";

export const FetchData = async (api, data, proxy = null) => {
  const { callApi } = useApiComponent(null);
  const response = await callApi({ ...api, paramObject: data });
  return response;
};

export const api = {
  lookups: () => FetchData(service.lookups, {}),
  worksorders: () => FetchData(service.worksorders, {}),
  importWorksorder: (data) => FetchData(service.importWorksorder, data),
  worksorder: (id) => FetchData(service.worksorder(id)),
  sendWorksorder: (id) => FetchData(service.sendWorksorder, { id }),
};

export default function useData() {
  const updateWorksorder = UsePusherRecoilSetter(updateWorksorderPusherSelector);
  const addWorksorder = UsePusherRecoilSetter(addWorksorderPusherSelector);
  const worksorders = UsePusherRecoilValue(worksordersPusherAtom);
  const [, setWorksorderId] = useRecoilState(worksorderIdAtom);
  const worksorder = useRecoilValue(worksorderAtom);
  const { getStateByCode } = useStates();
  const { state } = useStates();

  const [isLoading, setIsLoading] = React.useState(false);

  const importWorksorderAsync = async (data) => {
    setIsLoading(true);
    const insert = new FormData();
    insert.append("File", data.file.file.originFileObj);
    insert.append("Title", data.title);
    insert.append("Description", data.description ?? '');
    console.log("sum", data.sum);
    insert.append("Sum", data.sum);
    
    insert.append(
      "DeliveryDate",
      moment(data.deliveryDate).format("DD.MM.YYYY")
    );
    if (!!data.loadingDate)
      insert.append(
        "LoadingDate",
        moment(data.loadingDate).format("DD.MM.YYYY")
      );
    try {
      var inserted = await api.importWorksorder(insert);
      setIsLoading(false);
    } catch (error) {
      console.log("error", {...error});
      setIsLoading(false);
    }
    const {data: {worksorder} = {}} = inserted;
    addWorksorder(worksorder);
  };

  const changeState = async (id, code, save = false) => {
    const newState = await getStateByCode(code);
    updateWorksorder({ id, data: { state: newState } });
    if (save) {
      // save to database
    }
  };

  const sendWorksorder = async (id) => {
    changeState(id, states.SENDING);
    var response = await api.sendWorksorder(id);
    const {data} = response;
    updateWorksorder({id, data });
  };

  return {
    worksorders,
    importWorksorderAsync,
    isLoading,
    setWorksorderId,
    worksorder,
    changeState,
    sendWorksorder,
  };
}
