import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from 'containers/Header/index';
import Sidebar from 'containers/SideNav/index';
// import Footer from 'components/Footer';
// import Tour from '../components/Tour/index';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import ColorOption from 'containers/Customizer/ColorOption';
import { isIOS, isMobile } from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from '@bit/hlouzek.rhplus.top-nav';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import '@bit/hlouzek.rhplus.ag-grid-styles/AgGridStyles/ag-theme-radim.css';
import {PusherRecoilRoot} from '@bit/rhplus.wieldy.pusher-recoil/index';


class App extends React.Component {
  render() {
    const { match, drawerType, navigationStyle, horizontalNavPosition } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height')
    }
    else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height')
    }
    return (
      <div className={`app-container ${drawerStyle}`}>
        {/* <Tour/> */}
        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
              <TopNav styleName="app-top-header" />}
            <Header />
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
              <TopNav />}
          </div>
          <main className="app-main-content app-main-content-wrapper">
              <PusherRecoilRoot 
                clientKey={process.env.REACT_APP_PUSHER_CLIENT_KEY} 
                cluster={process.env.REACT_APP_PUSHER_CLUSTER}
                mode={process.env.NODE_ENV}
                channelName={process.env.REACT_APP_PUSHER_CHANNEL}
              >
                {/* <Switch> */}
                  <Redirect exact from={`${match.url}/`} to={`${match.url}/worksorders`} />                  
                  <Route exact path={`${match.url}/worksorders`} component={asyncComponent(() => import('./routes/Master'))} />
                  <Route exact path={`${match.url}/worksorders/:id`} component={asyncComponent(() => import('./routes/Detail'))} />
                {/* </Switch> */}
              </PusherRecoilRoot>
          </main>
        </div>
        <ColorOption />
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition }
};
export default withRouter(connect(mapStateToProps)(App)); 

