import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { configAtom, stateAtom, reducerAtom, sectionAtom } from "./atom";
import { usePusher } from "./pusher";

export const pusherSelector = ({ value, key, section, set }) => {
  return {
    value,
    key,
    section,
    set
  };
};

export const pusherAtom = ({ value, section, defaults }) => {
  return {
    value,
    section,
    defaults,
  };
};

export const reducerKey = (key, filter) => 
  `${key}${!filter ? "" : "_" + filter}`;

export const UsePusherRecoilValue = (pusherAtom) => {
  const { value, section, defaults } = pusherAtom;
  if (section === undefined) throw "FATAL ERROR: Section is not defined";
  const defaultsResult = useRecoilValue(defaults);
  const [sections, setSections] = useRecoilState(sectionAtom);
  const [state, setState] = useRecoilState(stateAtom);

  if (defaultsResult === undefined)
    return;

  const key = reducerKey(section, value);

  if (sections[key] === undefined || !sections[key].loaded) {
    setSections(current => ({ ...current, [key]: { loaded: true } }));
    setState({ ...state, [key]: defaultsResult });
  }
  return state[key] || [];
};

export const UsePusherRecoilSetter = (pusherSelector) => {
  const { trigger } = usePusher();
  const { section, key, set } = pusherSelector;
  const [reducer, setReducer] = useRecoilState(reducerAtom);

  const setter = (val) => {
    setReducer({ ...reducer, [key]: { section, set } });
    trigger(key, val).then();
  }
  return setter;
};
