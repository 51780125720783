import useServer from './server';
import {configAtom} from './atom';
import {useRecoilValue} from 'recoil';

const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
}

export const usePusher = () => {
  const {endpoint} = useServer();
  const config = useRecoilValue(configAtom);
  const {channelName} = config;

  const trigger = (eventName, data) => {
    return new Promise((resolve, reject) => {
      const url = `/pusher/trigger`;
  
      fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify({
          channel: channelName,
          eventName,
          data
        })
      }).then(result => resolve(result))
      .catch(error => reject(error))
    });
  }

  return {
    trigger
  }
}

