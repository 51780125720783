
const worksorders = {
  url: '/api/worksorder/worksorders',
  methodType: "POST",
  version: "1.0",
  compression: false,
}

const worksorder = (id) => {
  return {
    url: `/api/worksorder/detail/${id}`,
    methodType: "GET",
    version: "1.0",
    compression: true,
  };
};

const importWorksorder = {
  url: '/api/worksorder/importworksorder',
  methodType: "PUT",
  version: "1.0",
  compression: false,
}

const sendWorksorder = {
  url: '/api/worksorder/sendWorksorder',
  methodType: "POST",
  version: "1.0",
  compression: false
}

const lookups = {
  url: '/api/lookup/all',
  methodType: "GET",
  version: "1.0",
  compression: false
}
export default {
  worksorders,
  importWorksorder,
  worksorder,
  sendWorksorder,
  lookups
}