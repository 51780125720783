import { atom } from 'recoil';

export const configAtom = atom({
  key: 'config',
  default: {
    clientKey: '',
    cluster: 'eu',
    mode: 'development',
    channelName: ''
  },
  dangerouslyAllowMutability: true  
}) 

export const stateAtom = atom({
  key: 'stateAtom',
  default: [],
  dangerouslyAllowMutability: true  
})

export const reducerAtom = atom({
  key: 'reducerAtom',
  default: {},
  dangerouslyAllowMutability: true  
})

export const sectionAtom = atom({
  key: 'sectionAtom',
  default: {}
})