import * as React from "react";
import { RecoilRoot, useRecoilState, useRecoilValue } from "recoil";
import {configAtom, sectionAtom, stateAtom, reducerAtom} from './atom';
import { PusherProvider, useChannel, useEvent } from "@harelpls/use-pusher";

export default function PusherRecoilRoot(props) {  

  return (
      <RecoilRoot>
        <RecoilRootChildren {...props} />
      </RecoilRoot>
  );
}
const RecoilRootChildren = ({ clientKey, cluster, channelName, mode, children }) => {
  const [config, setConfig] = useRecoilState(configAtom);

  React.useEffect(() => {
    setConfig({clientKey, cluster, channelName, mode });
  }, [, clientKey, cluster, channelName])

  return (
    <PusherProvider clientKey={clientKey} cluster={cluster}>
      <PusherChildren channelName={channelName}>
        {children}
      </PusherChildren>
    </PusherProvider>)
};

const assembleSection = (section, data) => {
  if (typeof section === "function") {
    return section(data);
  } else {
    return section;
  }
}

export const PusherChildren = ({children, channelName}) => {
  const [state, setState] = useRecoilState(stateAtom);
  const reducer = useRecoilValue(reducerAtom);
  const sections = useRecoilValue(sectionAtom);
  const channel = useChannel(channelName);

  const bind = React.useCallback(() => {
    if (channel === undefined)
      return;
    
    channel.bind_global((event, data) => {
      const {section, set} = reducer[event];      
      const _section = assembleSection(section, data);
      const sectionState = state[_section] || [];
      const newSectionState = set(sectionState, data);
      setState({
        ...state,
        [_section]: newSectionState
      });
    });
  }, [,reducer]);

  const unbind = React.useCallback(() => {
    if (channel === undefined)
      return;
    channel.unbind_global();
  }, [,reducer]);

  React.useEffect(() => {
    bind();
    return () => unbind();
  });

  return (
    <React.Fragment>
      {children}      
    </React.Fragment>
  )
}