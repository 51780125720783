import {configAtom} from './atom';
import {useRecoilValue} from 'recoil';

 const useServer = () => {
  const config = useRecoilValue(configAtom);
  const {mode} = config;

  const endpoint = () => {
    if (mode === 'development') 
      return 'https://localhost:44386/';
    else
      return 'https://pusherservice.rhplus.cloud/';
  }

  return {
    endpoint
  }
}

export default useServer;